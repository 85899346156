import { InstrumentType } from '~/types/models';

import useDependencies from '../../useDependencies';
import { THandleRefresh } from '../Sidebar/types';

export type InstrumentFormProps = {
  options: ReturnType<typeof useDependencies>['options'];
  dependencies: ReturnType<typeof useDependencies>['dependencies'];
  isCreating?: boolean;
  onForceRefresh?: ({ id, resetExpanded }: THandleRefresh) => void;
  type?: InstrumentType;
};

export type TabRoute = {
  label: string;
  path: string;
  key: string;
};

export const enum TActiveSwitch {
  JSON = 'json',
  COMPILED = 'compiled',
  QUEUE = 'queue',
}

import { useMemo, useRef } from 'react';
import { ISelectOption, IconButton, Tooltip } from 'react-ui-kit-exante';

import { SortableStackValue } from '~/components';
import {
  DURATION_TYPE,
  INSTRUMENT_TYPE,
  MARKUP_UNIT,
  SPREAD_TYPE,
  SYMBOL_IDENTIFIER_TYPE,
} from '~/constants';
import {
  Autocomplete,
  BoolRow,
  BoolTable,
  MaturityDate,
  Select,
  TextInput,
} from '~/pages/Instruments/components/InstrumentForm/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';
import { CURRENCIES } from '~/services/constants';
import { InstrumentType } from '~/types/models';

import {
  Actions,
  Body,
  FormContainer,
  Header,
  Placeholder,
  Section,
  SectionTitle,
  Title,
} from '../styled';

import useBrokersForm from './useBrokersForm';

type BrokersProviderFormParams = {
  dependencies: InstrumentFormProps['dependencies'];
  id?: string;
  stackValue: SortableStackValue[];
  type?: InstrumentType;
};

const Form = ({
  dependencies,
  id,
  stackValue,
  type,
}: BrokersProviderFormParams) => {
  const { dirty, name, handleClose, handleDelete, handleSave } = useBrokersForm(
    stackValue,
    id,
  );

  const executionSchemeOptions = useMemo<ISelectOption[]>(
    () =>
      dependencies?.executionSchemes?.map((scheme) => ({
        value: scheme._id,
        label: scheme.name,
      })) || [],
    [dependencies?.executionSchemes],
  );

  const { current: symbolIdentifierTypeOptions } = useRef<ISelectOption[]>(
    SYMBOL_IDENTIFIER_TYPE.map((symbolIdentifierTypeItem) => ({
      value: symbolIdentifierTypeItem,
      label: symbolIdentifierTypeItem,
    })),
  );

  const { current: currencyOptions } = useRef<ISelectOption[]>(
    CURRENCIES.map((currencyItem) => ({
      value: currencyItem,
      label: currencyItem,
    })),
  );

  const { current: markupUnitOptions } = useRef<ISelectOption[]>(
    MARKUP_UNIT.map((markupUnitItem) => ({
      value: markupUnitItem,
      label: markupUnitItem,
    })),
  );

  const { current: spreadTypeOptions } = useRef<ISelectOption[]>(
    SPREAD_TYPE.map((spreadTypeItem) => ({
      value: spreadTypeItem,
      label: spreadTypeItem,
    })),
  );

  return (
    <FormContainer>
      <Header>
        <Title>{id && name && `Edit Broker Provider: ${name}`}</Title>
        <Actions>
          {id && name && (
            <>
              <Tooltip title="Apply changes and close form" placement="bottom">
                <IconButton
                  disabled={!dirty}
                  iconColor={dirty ? 'action' : 'secondary'}
                  iconName="SaveIcon"
                  iconSize={18}
                  label="OK"
                  onClick={handleSave}
                />
              </Tooltip>

              <Tooltip
                title="Delete Broker Provider from instrument"
                placement="bottom"
              >
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={18}
                  onClick={handleDelete}
                />
              </Tooltip>
            </>
          )}

          <Tooltip title="Discard changes and close form" placement="bottom">
            <IconButton
              iconColor="secondary"
              iconName="CloseIcon"
              iconSize={18}
              onClick={handleClose}
            />
          </Tooltip>
        </Actions>
      </Header>

      {!id && <Placeholder>Select Broker Provider</Placeholder>}
      {id && !name && <Placeholder>Broker Provider does not exist</Placeholder>}

      {id && name && (
        <Body>
          <TextInput path={`brokers.providerOverrides.${id}.symbolName`}>
            Symbol name
          </TextInput>
          <TextInput path={`brokers.providerOverrides.${id}.exchangeName`}>
            Exchange name
          </TextInput>
          <Select
            options={executionSchemeOptions}
            path={`brokers.providerOverrides.${id}.executionSchemeId`}
          >
            Execution scheme
          </Select>

          <Title>Symbol Identifier</Title>
          <Select
            options={symbolIdentifierTypeOptions}
            path={`brokers.providerOverrides.${id}.symbolIdentifier.type`}
          >
            Type
          </Select>
          <TextInput
            path={`brokers.providerOverrides.${id}.symbolIdentifier.identifier`}
          >
            Identifier
          </TextInput>
          <Select
            options={currencyOptions}
            path={`brokers.providerOverrides.${id}.currency`}
          >
            Currency
          </Select>
          <BoolTable>
            <BoolRow
              path={`brokers.providerOverrides.${id}.isShortSaleAvailable`}
            >
              Short sale available
            </BoolRow>
          </BoolTable>

          <Autocomplete
            path={`brokers.providerOverrides.${id}.availableOrderTypes`}
            options={DURATION_TYPE}
          >
            Available order types
          </Autocomplete>

          <TextInput path={`brokers.providerOverrides.${id}.priceMultiplier`}>
            Price multiplier
          </TextInput>
          <TextInput path={`brokers.providerOverrides.${id}.volumeMultiplier`}>
            Volume multiplier
          </TextInput>
          <TextInput
            path={`brokers.providerOverrides.${id}.strikePriceMultiplier`}
          >
            Strike price multiplier
          </TextInput>
          <TextInput path={`brokers.providerOverrides.${id}.markup`}>
            Markup
          </TextInput>
          <TextInput path={`brokers.providerOverrides.${id}.sellMarkup`}>
            Sell markup
          </TextInput>
          <TextInput path={`brokers.providerOverrides.${id}.buyMarkup`}>
            Buy markup
          </TextInput>
          <Select
            options={markupUnitOptions}
            path={`brokers.providerOverrides.${id}.markupUnit`}
          >
            Markup unit
          </Select>
          <TextInput path={`brokers.providerOverrides.${id}.symbolIdOverride`}>
            Symbol id override
          </TextInput>

          <Section>
            <SectionTitle>Markup reporting</SectionTitle>
            <TextInput
              path={`brokers.providerOverrides.${id}.markupReporting.account`}
            >
              Account
            </TextInput>
            <TextInput
              path={`brokers.providerOverrides.${id}.markupReporting.user`}
            >
              User
            </TextInput>
          </Section>

          <Section>
            <SectionTitle>Maturity date</SectionTitle>
            <MaturityDate
              path={`brokers.providerOverrides.${id}.maturityDate`}
            />
          </Section>

          {type === INSTRUMENT_TYPE.CALENDAR_SPREAD && (
            <>
              <TextInput path={`brokers.providerOverrides.${id}.legGap`}>
                Leg gap
              </TextInput>

              <Select
                options={spreadTypeOptions}
                path={`brokers.providerOverrides.${id}.spreadType`}
              >
                Spread type
              </Select>
            </>
          )}

          <BoolTable>
            <BoolRow path={`brokers.providerOverrides.${id}.emulateGtc`}>
              GTC emulation
            </BoolRow>
            <BoolRow path={`brokers.providerOverrides.${id}.emulateStop`}>
              Stop emulation
            </BoolRow>
            <BoolRow path={`brokers.providerOverrides.${id}.emulateDay`}>
              Day emulation
            </BoolRow>
            <BoolRow path={`brokers.providerOverrides.${id}.emulateMarket`}>
              Market emulation
            </BoolRow>
          </BoolTable>

          <TextInput path={`brokers.providerOverrides.${id}.minLotSize`}>
            Min Lot Size
          </TextInput>
          <TextInput
            path={`brokers.providerOverrides.${id}.contractMultiplier`}
          >
            Contract multiplier
          </TextInput>
          <BoolTable>
            <BoolRow path={`brokers.providerOverrides.${id}.anyTimeTriggering`}>
              Anytime triggering
            </BoolRow>
          </BoolTable>
        </Body>
      )}
    </FormContainer>
  );
};

export default Form;

import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Tooltip, Switch } from 'react-ui-kit-exante';

import { DeleteDialog, Modal } from '~/components';

import {
  AffectedSymbolsDialog,
  NamePanel,
  SmartTabs,
  SourceView,
  CompiledInstrumentView,
  Queue,
} from './components';
import { useInstrumentForm } from './hooks';
import {
  Actions,
  CloseButton,
  Container,
  Header,
  Loading,
  ActionButton,
  Title,
  ErrorWrapper,
  ErrorMessage,
  SwitchContainer,
} from './styled';
import {
  ArrayFields,
  BaseFields,
  Fundamentals,
  Identifiers,
  InstantExecution,
  MinorSettings,
  Overrides,
} from './tabs';
import { InstrumentFormProps, TActiveSwitch } from './types';

const InstrumentForm = ({
  dependencies,
  options,
  onForceRefresh,
}: InstrumentFormProps) => {
  const {
    activeSwitch,
    handleActiveSwitchClick,
    handleClose,
    handleCreateChild,
    handleDeclineSaveAsRequest,
    handleDeclineSubmit,
    handleDelete,
    handleDeleteDecline,
    handleDeleteRequest,
    handleGetAffectedSymbols,
    handleQueueAdd,
    handleQueueReset,
    handleQueueSave,
    handleSaveAsNew,
    handleSaveAsRequest,
    handleSubmit,
    isCreating,
    isDeleteDialogShown,
    isSetNameDialogShown,
    state,
    type,
  } = useInstrumentForm(dependencies, onForceRefresh);

  const title = useMemo(() => {
    const { name } = state.values;

    if (state.addingChildStarted) {
      return 'Add child instrument';
    }

    if (isCreating) {
      return `Create${name ? ':' : ''} ${name}`;
    }

    return name;
  }, [state.addingChildStarted, isCreating, state.values.name]);

  if (state.fetchStatus.error === 404) {
    return (
      <ErrorWrapper>
        <ErrorMessage>
          Instrument has been removed and may be visible in the tree because of
          proxy server cache delay
        </ErrorMessage>
      </ErrorWrapper>
    );
  }

  if (state.fetchStatus.error === 400) {
    return (
      <ErrorWrapper>
        <ErrorMessage>Bad Instrument ID</ErrorMessage>
      </ErrorWrapper>
    );
  }

  if (state.fetchStatus.error === 500) {
    return (
      <ErrorWrapper>
        <ErrorMessage>Unhandled Server Error</ErrorMessage>
      </ErrorWrapper>
    );
  }

  if (!state.ready || state.fetchStatus.pending) {
    return <Loading size="l" />;
  }

  return (
    <>
      <Container onSubmit={handleGetAffectedSymbols}>
        <Header>
          <Title>{title}</Title>
          <Actions>
            {state.queue.length > 0 && (
              <ActionButton
                iconColor="radical"
                iconName="DeleteIcon"
                label="Clear queue"
                onClick={handleQueueReset}
                type="button"
              />
            )}
            <ActionButton
              disabled={!state.dirty}
              iconColor="action"
              iconName="AddIcon"
              label="Add to queue"
              onClick={handleQueueAdd}
              type="button"
            />
            {state.queue.length > 0 && (
              <ActionButton
                iconColor="action"
                iconName="SaveIcon"
                label="Save queue"
                onClick={handleQueueSave}
                type="button"
              />
            )}
            {!state.saveStatus.pending && (
              <>
                <Tooltip title="Save instrument">
                  <ActionButton
                    disabled={!state.dirty}
                    iconColor="action"
                    iconName="SaveIcon"
                    label="Save"
                    type="submit"
                  />
                </Tooltip>
                {state.values?.name && (
                  <>
                    {!isCreating &&
                      !state.addingChildStarted &&
                      state.values.isAbstract &&
                      !state.dirty && (
                        <Tooltip title="Add child Instrument">
                          <ActionButton
                            iconColor="action"
                            iconName="AddIcon"
                            label="Add Child"
                            onClick={handleCreateChild}
                            type="button"
                          />
                        </Tooltip>
                      )}
                    {!isCreating && !state.addingChildStarted && !state.dirty && (
                      <Tooltip title="Save as New">
                        <ActionButton
                          iconColor="action"
                          iconName="AddIcon"
                          label="Save as New"
                          onClick={handleSaveAsRequest}
                          type="button"
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Delete instrument">
                      <ActionButton
                        type="button"
                        iconName="DeleteIcon"
                        iconColor="radical"
                        onClick={handleDeleteRequest}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            )}
            {state.saveStatus.pending && <Loading size="m" />}
            <CloseButton
              iconName="CloseIcon"
              iconColor="promo"
              onClick={handleClose}
            />
          </Actions>
        </Header>

        <SmartTabs disabled={Boolean(activeSwitch)} values={state.values}>
          <SwitchContainer>
            {state.queue.length > 0 && (
              <Switch
                checked={activeSwitch === TActiveSwitch.QUEUE}
                label="Queue"
                onChange={() => handleActiveSwitchClick(TActiveSwitch.QUEUE)}
              />
            )}

            <Switch
              checked={activeSwitch === TActiveSwitch.JSON}
              label="JSON"
              onChange={() => handleActiveSwitchClick(TActiveSwitch.JSON)}
              style={{ marginLeft: '10px' }}
            />

            <Switch
              checked={activeSwitch === TActiveSwitch.COMPILED}
              label="Compiled"
              onChange={() => handleActiveSwitchClick(TActiveSwitch.COMPILED)}
              style={{ marginLeft: '10px' }}
            />
          </SwitchContainer>
        </SmartTabs>

        {activeSwitch === TActiveSwitch.QUEUE && <Queue />}
        {activeSwitch === TActiveSwitch.JSON && (
          <SourceView dependencies={dependencies} />
        )}
        {activeSwitch === TActiveSwitch.COMPILED && <CompiledInstrumentView />}
        {!activeSwitch && (
          <Routes>
            <Route
              path=""
              element={
                <BaseFields
                  isCreating={isCreating || state.addingChildStarted}
                  dependencies={dependencies}
                  options={options}
                  type={type}
                />
              }
            />
            <Route
              path="instant-execution/*"
              element={
                <InstantExecution
                  options={options}
                  dependencies={dependencies}
                />
              }
            />
            <Route path="arrays/*" element={<ArrayFields type={type} />} />
            <Route path="settings/*" element={<MinorSettings />} />
            <Route
              path="overrides/*"
              element={
                <Overrides
                  dependencies={dependencies}
                  options={options}
                  type={type}
                />
              }
            />
            <Route
              path="identifiers/*"
              element={
                <Identifiers
                  type={type}
                  dependencies={dependencies}
                  options={options}
                />
              }
            />
            <Route path="fundamentals/*" element={<Fundamentals />} />
          </Routes>
        )}
      </Container>
      <DeleteDialog
        isShown={isDeleteDialogShown}
        isLoading={state.deleteStatus.pending}
        onConfirm={handleDelete}
        onDecline={handleDeleteDecline}
      />
      {isSetNameDialogShown && (
        <Modal
          onPressEscape={handleDeclineSaveAsRequest}
          open={isSetNameDialogShown}
        >
          <NamePanel
            onCancel={handleDeclineSaveAsRequest}
            onSubmit={handleSaveAsNew}
          />
        </Modal>
      )}
      <AffectedSymbolsDialog
        affectedSymbolsAmount={state.affectedSymbolsAmount}
        isLoading={state.affectedSymbolsStatus.pending}
        isShown={state.isAffectedSymbolsDialogShown}
        onConfirm={handleSubmit}
        onDecline={handleDeclineSubmit}
      />
    </>
  );
};

export default InstrumentForm;

const isInsideRun = () =>
  window.runUIhistoryContainer && window.SYMBOLDB_UI_REACT;

type ApiType = 'SYMBOLDB_EDITOR' | 'STATIC_DATA' | 'BO';

export const DEFAULT_DOMAINS = {
  SYMBOLDB_EDITOR: {
    stage: 'symboldb-stage.exante.eu/symboldb-editor',
    test: 'symboldb-test.exante.eu/symboldb-editor',
  },
  STATIC_DATA: {
    stage: 'sds-stage.exante.eu',
    test: 'sds-test.exante.eu',
  },
  BO: {
    stage: 'node-back-stage.exante.eu',
    test: 'node-back-test.exante.eu',
  },
};

export const getEnv = () => {
  const isStage = window.location.host.includes('stage');
  const isTest = window.location.host.includes('test');

  if (isTest) {
    return 'test';
  }

  if (isStage) {
    return 'stage';
  }

  return 'stage';
};

export const ENVIRONMENT = getEnv();

const getFallbackErrorMessage = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] SymbolDB application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getApiDomain = (
  apiType: ApiType,
  apiVersion = '',
  fallback = `https://${DEFAULT_DOMAINS[apiType][ENVIRONMENT]}`,
) => {
  const backupUrl = `https://${DEFAULT_DOMAINS[apiType][ENVIRONMENT]}${apiVersion}`;

  if (isInsideRun()) {
    const url = window.SYMBOLDB_UI_REACT?.api_urls?.[apiType];

    if (url) {
      return `https://${url}${apiVersion}`;
    }

    console.warn(
      getFallbackErrorMessage(
        `getBase${apiType}Url`,
        `window.SYMBOLDB_UI_REACT.api_urls.${apiType}`,
        `DEFAULT_DOMAINS[${apiType}]`,
      ),
    );

    return backupUrl || fallback;
  }

  return backupUrl || fallback;
};

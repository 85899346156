import { initApp } from './utils/initApp';

window.SYMBOLDB_UI_REACT = {
  ...(window.SYMBOLDB_UI_REACT || {}),
  render: (node: Element) => initApp(node),
};

// Case when rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}

export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export const API_V1 = '/api/v1.0';
export const API = '/api';

import { INSTRUMENT_TYPE } from '~/constants';
import { InstrumentType } from '~/types/models';

import { Splits, StrikePrices, StringArray, TreePath } from '../components';

import { Container, Column } from './styled';

type ArrayFieldsProps = {
  type: InstrumentType;
};

const ArrayFields = ({ type }: ArrayFieldsProps) => {
  return (
    <Container>
      <Column>
        {type === INSTRUMENT_TYPE.OPTION && <StrikePrices />}
        <TreePath path="treePath" />
        <StringArray path="treePathOverride" placeholder="Enter key word">
          Tree Path Override
        </StringArray>
        <StringArray path="tags" placeholder="Enter instrument tag">
          Instrument tags
        </StringArray>
        <Splits />
        <StringArray
          path="stampDuty.countryOfIncorporation"
          placeholder="Enter key word"
        >
          Country of incorporation
        </StringArray>
      </Column>
    </Container>
  );
};

export default ArrayFields;

import { IconButton, Input, InputDatePicker } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import {
  ArrayContainer,
  ButtonsContainer,
  DeleteButton,
  SpacedContainer,
  Title,
  VerticalContainer,
} from '../styled';

import {
  CircleClockContainer,
  DateTimeWrapper,
  IgnoreAutoScaleCheckbox,
  SplitRow,
  TimeInput,
} from './styled';
import useSplits from './useSplits';

const Splits = () => {
  const {
    disabled,
    handleAddItem,
    handleRemoveItem,
    handleReset,
    inheritTitle,
    isInherited,
    isResetButtonActive,
    registerCheckboxField,
    registerDateField,
    registerDateTimeField,
    registerDigitsField,
    value,
  } = useSplits();

  return (
    <ArrayContainer disabled={disabled}>
      <SpacedContainer>
        <Title>Splits</Title>

        <ButtonsContainer>
          {isInherited && (
            <InheritButton
              value={inheritTitle}
              isActive={isResetButtonActive}
              onReset={handleReset}
            />
          )}

          <IconButton
            onClick={handleAddItem}
            iconName="AddIcon"
            iconColor="promo"
            iconSize={22}
          />
        </ButtonsContainer>
      </SpacedContainer>
      <VerticalContainer>
        {value?.map((item, index) => (
          <SplitRow key={item.uuid}>
            <InputDatePicker
              {...registerDateField(
                `${index}.timestamp`,
                `splits[${index}].timestamp`,
              )}
            />

            <TimeInput
              {...registerDateTimeField(
                `${index}.time`,
                `splits[${index}].time`,
                `${index}.timestamp`,
              )}
              iconRight={
                <DateTimeWrapper>
                  <CircleClockContainer applyStates size={24} />
                </DateTimeWrapper>
              }
            />

            <Input
              fullWidth
              label="From"
              {...registerDigitsField(`${index}.coefficient.from`)}
            />

            <Input
              fullWidth
              label="To"
              {...registerDigitsField(`${index}.coefficient.to`)}
            />

            <IgnoreAutoScaleCheckbox
              label="Ignore Auto Scale"
              {...registerCheckboxField(`${index}.ignoreAutoScale`)}
            />

            <DeleteButton
              iconName="DeleteIcon"
              iconColor="radical"
              onClick={() => handleRemoveItem(index)}
            />
          </SplitRow>
        ))}
      </VerticalContainer>
    </ArrayContainer>
  );
};

export default Splits;

import { apiService } from '~/services/api/api.service';
import { API_V1 } from '~/services/api/constants';
import { getApiDomain } from '~/services/api/getApiDomain';
import { Endpoint } from '~/services/constants';
import { getSessionIdFromCookie } from '~/utils/session';

const SYMBOLDB_API = apiService(getApiDomain('SYMBOLDB_EDITOR', API_V1));

export const batchUpdate = async (payload: string): Promise<void> => {
  await SYMBOLDB_API.post({
    endPoint: `${Endpoint.BatchQueue}`,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
    headers: {
      'Content-Type': 'application/x-ld-json',
      Accept: 'application/json',
    },
  });
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { get, omitBy } from 'lodash';

import {
  LegacyInstrumentPayload,
  InstrumentAccount,
  InstrumentBrokers,
  InstrumentFeeds,
  InstrumentGateway,
  LegacyInstrumentResponse,
  FeedGateway,
  BrokerAccount,
  InstrumentSplit,
} from '~/types/models';
import { checkIsTimestampInMilliseconds } from '~/utils/checkIsTimestampInMilliseconds';

import { InstrumentFormProps } from '../components/InstrumentForm/types';

import { InstrumentData, PatchedAccount, PatchedGateway } from './types';

dayjs.extend(utc);

export const getErrorTitle = (
  message?: string,
  errors?: Record<string, string>,
): string => {
  try {
    if (!errors) {
      if (!message) {
        return 'Unhandled Error';
      }

      if (typeof message === 'string') {
        return message;
      }

      return JSON.stringify(message);
    }

    const keys = Object.keys(errors);

    const payload = keys.reduce<string[]>(
      (result, key) => {
        if (typeof errors[key] !== 'string') {
          return result;
        }

        return [...result, `${key}: ${errors[key]}`];
      },
      [message || 'Unhandled Error'],
    );

    return payload.join('\n');
  } catch (error) {
    return 'Caught Unhandled Error';
  }
};

export const getErrorDescription = (
  message?: string,
  errors?: Record<string, string>,
): string => {
  try {
    if (!errors) {
      if (!message) {
        return 'Unhandled Error';
      }

      if (typeof message === 'string') {
        return message;
      }

      return JSON.stringify(message);
    }

    const keys = Object.keys(errors);

    const payload = keys.reduce<string[]>(
      (result, key) => {
        return [...result, `${key}: ${JSON.stringify(errors[key])}`];
      },
      [message || 'Unhandled Error'],
    );

    return payload.join('\n');
  } catch (error) {
    return 'Caught Unhandled Error';
  }
};

export const getValue = (
  path: string,
  instrument?: InstrumentData,
): unknown => {
  if (!instrument) {
    return undefined;
  }

  const value = get(instrument, path);

  switch (typeof value) {
    case 'string':
    case 'number':
      return value.toString();

    default:
      return value;
  }
};

const getInheritedValueInstrument = (
  path: string,
  data?: InstrumentData[],
): InstrumentData | undefined => {
  if (!data || data.length === 0) {
    return undefined;
  }

  return getValue(path, data[0]) !== undefined
    ? data[0]
    : getInheritedValueInstrument(path, data.slice(1));
};

export const getInheritValue = <T>(
  path: string,
  data?: InstrumentData[],
): T | undefined => {
  return getValue(path, getInheritedValueInstrument(path, data)) as T;
};

export const getSelfValue = <T>(path: string, value?: InstrumentData): T => {
  return getValue(path, value) as T;
};

export const getPatchedAccounts = (
  accounts?: InstrumentAccount[],
): Record<string, PatchedAccount> | undefined => {
  if (!accounts || accounts.length === 0) {
    return undefined;
  }

  return accounts.reduce<Record<string, PatchedAccount>>(
    (result, item, order) => {
      return {
        ...result,
        [item.accountId]: { ...item.account, order },
      };
    },
    {},
  );
};
export const getPatchedGateways = (
  gateways?: InstrumentGateway[],
): Record<string, PatchedGateway> | undefined => {
  if (!gateways || gateways.length === 0) {
    return undefined;
  }

  return gateways.reduce<Record<string, PatchedGateway>>(
    (result, item, order) => {
      return {
        ...result,
        [item.gatewayId]: { ...item.gateway, order },
      };
    },
    {},
  );
};

export const getPatchedBrokers = (
  brokers?: InstrumentBrokers,
): InstrumentData['brokers'] => {
  if (!brokers) {
    return undefined;
  }

  return {
    ...brokers,
    accounts: getPatchedAccounts(brokers.accounts),
  };
};
export const getPatchedFeeds = (
  feeds?: InstrumentFeeds,
): InstrumentData['feeds'] => {
  if (!feeds) {
    return undefined;
  }

  return {
    ...feeds,
    gateways: getPatchedGateways(feeds.gateways),
  };
};

const getPatchedSplits = (
  splits: InstrumentSplit[] | undefined,
): InstrumentSplit[] | undefined => {
  return splits?.map((split) => {
    const timestamp = checkIsTimestampInMilliseconds(split.timestamp)
      ? split.timestamp / 1000
      : Number(split.timestamp);

    const hour = String(
      dayjs(timestamp * 1000)
        .utc()
        .hour(),
    ).padStart(2, '0');
    const minute = String(
      dayjs(timestamp * 1000)
        .utc()
        .minute(),
    ).padStart(2, '0');

    const time = `${hour}:${minute}`;

    return {
      ...split,
      ...(split.timestamp && {
        timestamp,
      }),
      ...(time && {
        time,
      }),
    };
  });
};

export const getInstrumentData = (
  response: LegacyInstrumentResponse,
): InstrumentData[] =>
  response.map((item) => ({
    ...item,
    splits: getPatchedSplits(item.splits),
    brokers: getPatchedBrokers(item.brokers),
    feeds: getPatchedFeeds(item.feeds),
  }));

export const getAccounts = (
  accounts?: Record<string, PatchedAccount>,
  source: BrokerAccount[] = [],
): InstrumentAccount[] | undefined => {
  if (!accounts) {
    return undefined;
  }

  const sorted = Object.keys(accounts).sort((keyA, keyB) => {
    const valueA = accounts[keyA];
    const valueB = accounts[keyB];

    return valueA.order - valueB.order;
  });

  return sorted.map((accountId) => {
    const { order, ...account } = accounts[accountId];
    const sourceAccount = source.find(({ _id }) => _id === accountId);

    delete account.isEmpty;

    return {
      account: {
        ...account,
        gatewayId: sourceAccount?.gatewayId || account.gatewayId,
        providerId: sourceAccount?.providerId || account.providerId,
      },
      accountId,
    };
  });
};

export const getGateways = (
  gateways?: Record<string, PatchedGateway>,
  source: FeedGateway[] = [],
): InstrumentGateway[] | undefined => {
  if (!gateways) {
    return undefined;
  }

  const sorted = Object.keys(gateways).sort((keyA, keyB) => {
    const valueA = gateways[keyA];
    const valueB = gateways[keyB];

    return valueA.order - valueB.order;
  });

  return sorted.map((gatewayId) => {
    const { order, ...gateway } = gateways[gatewayId];
    const sourceGateway = source.find(({ _id }) => _id === gatewayId);

    return {
      gateway: {
        ...gateway,
        providerId: sourceGateway?.providerId || gateway.providerId,
      },
      gatewayId,
    };
  });
};

export const getBrokers = (
  patchedBrokers?: InstrumentData['brokers'],
  accounts: BrokerAccount[] = [],
): InstrumentBrokers | undefined => {
  if (!patchedBrokers) {
    return undefined;
  }

  const providerOverrides = patchedBrokers.providerOverrides;
  const brokerAccounts = getAccounts(patchedBrokers.accounts, accounts);

  return {
    ...(providerOverrides && { providerOverrides }),
    ...(brokerAccounts && { accounts: brokerAccounts }),
  };
};

export const getFeeds = (
  patchedFeeds?: InstrumentData['feeds'],
  gateways: FeedGateway[] = [],
): InstrumentFeeds | undefined => {
  if (!patchedFeeds) {
    return undefined;
  }

  const providerOverrides = patchedFeeds.providerOverrides;
  const feedGateways = getGateways(patchedFeeds.gateways, gateways);

  return {
    ...(providerOverrides && {
      providerOverrides,
    }),
    ...(feedGateways && { gateways: feedGateways }),
  };
};

const getSplits = (
  splits: InstrumentSplit[] | undefined,
): InstrumentSplit[] | undefined => {
  return splits?.map(({ time, ...split }) => {
    let timestamp = checkIsTimestampInMilliseconds(split.timestamp)
      ? split.timestamp
      : Number(split.timestamp) * 1000;

    if (time) {
      const hour = Number(time.split(':')[0]);
      const minute = Number(time.split(':')[1]);

      timestamp =
        dayjs(timestamp).utc().set('hour', hour).set('minute', minute).unix() *
        1000;
    }

    return {
      ...split,
      ...(split.timestamp && {
        timestamp,
      }),
    };
  });
};

export const getInstrumentPayload = (
  data: InstrumentData,
  dependencies: InstrumentFormProps['dependencies'],
): LegacyInstrumentPayload => {
  const {
    _creationTime: creationTime,
    _id: id,
    _lastUpdateTime: lastUpdateTime,
    _rev: rev,
    splits,
    ...payload
  } = data;
  const brokers = getBrokers(payload.brokers, dependencies.brokerAccounts);
  const feeds = getFeeds(payload.feeds, dependencies.feedGateways);

  const result = {
    ...payload,
    ...(getSplits(splits)?.length && {
      splits: getSplits(splits),
    }),
    ...(brokers && { brokers }),
    ...(feeds && { feeds }),
    _creationTime: creationTime,
    _id: id,
    _lastUpdateTime: lastUpdateTime,
    _rev: rev,
  };

  return <LegacyInstrumentPayload>omitBy(result, (v) => v === '');
};

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

import dayjs from 'dayjs';
import { IconButton, InputDatePicker } from 'react-ui-kit-exante';

import { InheritButton } from '../InheritButton';
import { InputContainer } from '../styled';

import { CheckboxContainer, DatePickerWrapper } from './styled';
import { MaturityDateProps } from './types';
import useMaturityDate from './useMaturityDate';

const MaturityDate = ({ path }: MaturityDateProps) => {
  const {
    disabled,
    error,
    isInherited,
    isResetButtonActive,
    inheritTitle,
    specifyDay,
    value,
    handleChange,
    handleDateClear,
    handleReset,
    handleSpecifyDay,
  } = useMaturityDate(path);

  return (
    <InputContainer disabled={disabled} inherited={isInherited}>
      {isInherited && (
        <InheritButton
          value={inheritTitle}
          isActive={isResetButtonActive}
          onReset={handleReset}
        />
      )}

      <DatePickerWrapper>
        <InputDatePicker
          dateFormat={specifyDay ? 'dd.MM.yyyy' : 'MM.yyyy'}
          disabled={disabled}
          inputProps={{
            error: Boolean(error),
            fullWidth: true,
            label: 'Maturity Date',
            message: error,
          }}
          onChange={handleChange}
          selected={value}
          value={
            value
              ? dayjs(value).format(specifyDay ? 'DD.MM.YYYY' : 'MM.YYYY')
              : undefined
          }
        />
        <IconButton
          disabled={disabled || !value}
          iconColor="secondary"
          iconName="CloseIcon"
          iconSize={28}
          onClick={handleDateClear}
        />
      </DatePickerWrapper>

      <CheckboxContainer
        checked={specifyDay}
        label="Specify Day"
        onChange={handleSpecifyDay}
      />
    </InputContainer>
  );
};

export default MaturityDate;

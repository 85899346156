import { SortableStack } from '~/components';
import { InstrumentFormProps } from '~/pages/Instruments/components/InstrumentForm/types';

import { Container, StackContainer } from '../styled';

import Form from './Form';
import useBrokersList from './useBrokersList';

const Brokers = ({ dependencies, type }: InstrumentFormProps) => {
  const { value, options, selected, handleChange, handleSelect } =
    useBrokersList(dependencies);

  return (
    <Container>
      <StackContainer>
        <SortableStack
          searchable
          value={value}
          options={options}
          selected={selected}
          onChange={handleChange}
          onSelect={handleSelect}
        />
      </StackContainer>

      <Form
        dependencies={dependencies}
        id={selected}
        stackValue={value}
        type={type}
      />
    </Container>
  );
};

export default Brokers;

import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { InstrumentContext } from '~/pages/Instruments/context';
import { InstrumentActions } from '~/pages/Instruments/context/actions';
import {
  getInheritValue,
  getSelfValue,
} from '~/pages/Instruments/context/utils';

import { isNumeric } from './helpers';

const useTextInput = (path: string, isDisabled?: boolean) => {
  const { state, dispatch } = useContext(InstrumentContext);
  const selfValue = getSelfValue<string | string[]>(path, state.values);
  const inheritValue = getInheritValue<string>(path, state.parents);

  const [value, setValue] = useState(selfValue || inheritValue);

  const disabled = Boolean(state.saveStatus.pending || isDisabled);
  const isInherited = inheritValue !== undefined;
  const isResetButtonActive = Boolean(inheritValue && selfValue !== undefined);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      setValue(target.value);

      let targetValue: string | number = target.value;

      if (isNumeric(target.value)) {
        targetValue = Number(target.value);

        dispatch({
          type: InstrumentActions.SetFieldValue,
          payload: { path, value: targetValue },
        });

        return;
      }

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: targetValue || undefined },
      });
    }
  };

  const handleReset = () => {
    if (isInherited && !disabled) {
      setValue(inheritValue);

      dispatch({
        type: InstrumentActions.SetFieldValue,
        payload: { path, value: undefined },
      });
    }
  };

  const error = state.errors.get(path);

  useEffect(() => {
    if (path === 'name' && !selfValue && state.addingChildStarted) {
      setValue('');

      return;
    }

    setValue(selfValue || inheritValue);
  }, [path, selfValue, inheritValue]);

  return {
    isInherited,
    isResetButtonActive,
    inheritValue,
    error,
    value,
    disabled,
    handleChange,
    handleReset,
  };
};

export default useTextInput;

import { Checkbox, styled } from 'react-ui-kit-exante';

export const DatePickerWrapper = styled('div')`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 24px;

  & > div > div {
    width: 100%;
  }
`;

export const CheckboxContainer = styled(Checkbox)`
  margin-top: 10px;
`;

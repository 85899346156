import { Notification } from 'react-ui-kit-exante';

import {
  RunAllProps,
  CreateTaskProps,
  EventsParamsProps,
  CreateScheduleProps,
} from '../pages/StaticData/Main/types';
import { IMapping } from '../pages/StaticData/Mapping/types';

import { apiService } from './api/api.service';
import { getApiDomain } from './api/getApiDomain';
import { Endpoint } from './constants';
import { getSessionIdFromCookie } from './cookies/cookies.service';

const STATIC_DATA_API = apiService(getApiDomain('STATIC_DATA'));

export const staticDataService = () => {
  const getConfigs = async () => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Configs}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSingleConfig = async (id?: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Configs}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createConfig = async (values: IMapping) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.Configs}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateReportingProvider = async (values: IMapping, id?: string) => {
    try {
      const result = await STATIC_DATA_API.put({
        endPoint: `${Endpoint.Configs}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteConfig = async (id?: string) => {
    try {
      const result = await STATIC_DATA_API.delete({
        endPoint: `${Endpoint.Configs}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getEvents = async (params: EventsParamsProps) => {
    const { limit, skip, step, sorting, status, taskId } = params;
    const sortingObj = (sorting as Record<string, unknown>[])?.[0];
    const sort = sortingObj
      ? `&orderBy=${sortingObj.id}&sort=${sortingObj.desc ? 'DESC' : 'ASC'}`
      : '';

    try {
      const { data } = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Events}?withAll=false&limit=${
          limit || '10'
        }&skip=${skip || '0'}&step=${step || ''}&status=${
          status || ''
        }${sort}&taskId=${taskId}`,
        sessionToken: getSessionIdFromCookie() || '',
      });

      return data;
    } catch (err) {
      Notification.error({
        title: 'While fetching events occurred error',
      });

      return {
        count: 0,
        events: [],
      };
    }
  };

  const getSingleEvent = async (id?: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Events}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getProviders = async () => {
    try {
      const { data } = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Providers}`,
        sessionToken: getSessionIdFromCookie() || '',
      });

      return data;
    } catch (err: any) {
      Notification.error({
        title: err?.message || 'While fetching providers occurred error',
      });

      return [];
    }
  };

  const getMaps = async (providerId?: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Maps}?providerId=${providerId || ''}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getMap = async (id?: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Maps}/${id || ''}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createMaps = async (values: IMapping) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.Maps}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateMap = async (values: IMapping, id?: string) => {
    try {
      const result = await STATIC_DATA_API.put({
        endPoint: `${Endpoint.Maps}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteMaps = async (id?: string) => {
    try {
      const result = await STATIC_DATA_API.delete({
        endPoint: `${Endpoint.Maps}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createTask = async (values: CreateTaskProps) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.Tasks}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const changedTask = async (values: CreateTaskProps, id: string | null) => {
    try {
      const result = await STATIC_DATA_API.put({
        endPoint: `${Endpoint.Tasks}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createSchedule = async (values: CreateScheduleProps) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.Schedules}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const changeSchedule = async (values: CreateScheduleProps, id: string) => {
    try {
      const result = await STATIC_DATA_API.put({
        endPoint: `${Endpoint.Schedules}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const runAll = async (values: RunAllProps) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.RunAll}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getRunStep = async (id: string, all: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.RunStep}/${id}?all=${all}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const runStep = async (values: RunAllProps) => {
    try {
      const result = await STATIC_DATA_API.post({
        endPoint: `${Endpoint.RunStep}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getTaskId = async (id: string) => {
    try {
      const { data } = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Tasks}?folderId=${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });

      return data;
    } catch (err: any) {
      Notification.error({
        title: err?.message || 'While fetching taskId occurred error',
      });

      return {};
    }
  };

  const getTask = async (id: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Tasks}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      Notification.error({
        title: err?.message || 'While fetching task occurred error',
      });

      return {};
    }
  };

  const getSchedulesId = async (id: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Schedules}?taskId=${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      Notification.error({
        title: err?.message || 'While fetching schedulesId occurred error',
      });

      return {};
    }
  };

  const getSchedules = async (id: string) => {
    try {
      const result = await STATIC_DATA_API.get({
        endPoint: `${Endpoint.Schedules}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const fetchLegacyDataStatic = async (
    id?: string,
  ): Promise<{ tasks: any }> => {
    let tasksData = {};

    if (!id) {
      return {
        tasks: tasksData,
      };
    }

    const taskResponse = await getTaskId(id);
    const { tasks } = taskResponse;

    if (tasks.length) {
      const [task] = tasks;
      tasksData = await getTask(task.taskId);
    }

    return {
      tasks: tasksData,
    };
  };

  return {
    changedTask,
    changeSchedule,
    createConfig,
    createMaps,
    createSchedule,
    createTask,
    deleteConfig,
    deleteMaps,
    fetchLegacyDataStatic,
    getConfigs,
    getEvents,
    getMap,
    getMaps,
    getProviders,
    getRunStep,
    getSchedules,
    getSchedulesId,
    getSingleConfig,
    getSingleEvent,
    getTask,
    getTaskId,
    runAll,
    runStep,
    updateMap,
    updateReportingProvider,
  };
};

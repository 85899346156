import { apiService } from '~/services/api/api.service';
import { API, API_V1 } from '~/services/api/constants';
import { getApiDomain } from '~/services/api/getApiDomain';
import { Endpoint } from '~/services/constants';
import { getSessionIdFromCookie } from '~/services/cookies/cookies.service';
import {
  AffectedSymbols,
  LegacyInstrumentPayload,
  LegacyInstrumentResponse,
} from '~/types/models';
import { EmptyResponse } from '~/types/shared';

const SYMBOLDB_API = apiService(getApiDomain('SYMBOLDB_EDITOR', API_V1));
const BO_API = apiService(getApiDomain('BO'));

export const fetchLegacyInstrument = async (
  id?: string,
): Promise<LegacyInstrumentResponse> => {
  if (!id) {
    return [];
  }

  const { data } = await SYMBOLDB_API.get({
    endPoint: `${Endpoint.Instruments}?childId=${id}`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return (data as LegacyInstrumentResponse).sort(
    (a, b) => b.path.length - a.path.length,
  );
};

export const saveInstrument = async (
  id: string,
  payload: Partial<LegacyInstrumentPayload>,
): Promise<EmptyResponse> => {
  const { data } = await BO_API.post({
    endPoint: `${API}${Endpoint.Instruments}/${id}`,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
  });

  return data;
};

export const createInstrument = async (
  payload: Partial<LegacyInstrumentPayload>,
): Promise<EmptyResponse> => {
  const { data } = await BO_API.post({
    endPoint: `${API}${Endpoint.Instruments}`,
    sessionToken: getSessionIdFromCookie() || '',
    data: payload,
  });

  return data;
};

export const deleteInstrument = async (id: string): Promise<EmptyResponse> => {
  const { data } = await BO_API.delete({
    endPoint: `${API}${Endpoint.Instruments}/${id}`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return data;
};

export const checkLegacyInstrumentDependencies = async (
  id?: string,
): Promise<AffectedSymbols> => {
  if (!id) {
    return { affectedSymbols: 0 };
  }

  const { data } = await SYMBOLDB_API.get({
    endPoint: `${Endpoint.Instruments}/${id}/deps`,
    sessionToken: getSessionIdFromCookie() || '',
  });

  return data;
};

import { apiService } from '~/services/api/api.service';
import { getApiDomain } from '~/services/api/getApiDomain';

import { getQueryURL, getSessionProps } from '../utils';

import {
  GetInstrumentsParams,
  GetInstrumentsResponse,
  GetTreeParams,
  GetTreeResponse,
} from './types';

const BO_API = apiService(getApiDomain('BO'));

export const getInstruments = async (
  params: GetInstrumentsParams,
): GetInstrumentsResponse => {
  const endPoint = getQueryURL('/api/instruments', params);

  const { data } = await BO_API.get({ endPoint, ...getSessionProps() });

  return data;
};

export const getInstrumentsTree = async (
  params?: GetTreeParams,
): GetTreeResponse => {
  const endPoint = getQueryURL('/api/instruments/tree', params);

  const { data } = await BO_API.get({ endPoint, ...getSessionProps() });

  return data;
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchLegacyInstrument } from '~/api/SymbolDBService';

import { initialState } from '../TreeContext/constants';

import type { TreeRouteParams } from './types';

const useTree = () => {
  const { id } = useParams<TreeRouteParams>();
  const [instrument, setInstrument] = useState(initialState);

  const fetchTreeValues = async () => {
    try {
      const response = await fetchLegacyInstrument(id);

      if (response.length > 1) {
        const [mainInstrument, parentInstrument] = response;

        if (mainInstrument && parentInstrument) {
          setInstrument({
            exchangeId: mainInstrument?.exchangeId || '',
            id: mainInstrument?._id,
            name: mainInstrument?.name,
            type: mainInstrument?.type || parentInstrument?.type || '',
          });
        }
      } else {
        setInstrument(initialState);
      }
    } catch (e) {
      setInstrument(initialState);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTreeValues();
    }
  }, [id]);

  return {
    instrument,
  };
};

export default useTree;

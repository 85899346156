import { useRef } from 'react';
import { ISelectOption, IconButton, Tooltip } from 'react-ui-kit-exante';

import { SortableStackValue } from '~/components';
import {
  INSTRUMENT_TYPE,
  MARKUP_UNIT,
  SPREAD_TYPE,
  SYMBOL_IDENTIFIER_TYPE,
} from '~/constants';
import {
  TextInput,
  BoolTable,
  BoolRow,
  Select,
  MaturityDate,
} from '~/pages/Instruments/components/InstrumentForm/components';
import { CURRENCIES } from '~/services/constants';
import { InstrumentType, ProviderTypes } from '~/types/models';

import {
  Actions,
  Body,
  FormContainer,
  Header,
  Placeholder,
  Section,
  SectionTitle,
  Title,
} from '../styled';

import { ProviderType } from './ProviderType';
import useFeedsForm from './useFeedsForm';

type FeedsProviderFormProps = {
  id?: string;
  stackValue: SortableStackValue[];
  type?: InstrumentType;
};

const Form = ({ id, stackValue, type }: FeedsProviderFormProps) => {
  const { dirty, name, providerType, handleClose, handleDelete, handleSave } =
    useFeedsForm(stackValue, id);

  const { current: symbolIdentifierTypeOptions } = useRef<ISelectOption[]>(
    SYMBOL_IDENTIFIER_TYPE.map((symbolIdentifierTypeItem) => ({
      value: symbolIdentifierTypeItem,
      label: symbolIdentifierTypeItem,
    })),
  );

  const { current: currencyOptions } = useRef<ISelectOption[]>(
    CURRENCIES.map((currencyItem) => ({
      value: currencyItem,
      label: currencyItem,
    })),
  );

  const { current: markupUnitOptions } = useRef<ISelectOption[]>(
    MARKUP_UNIT.map((markupUnitItem) => ({
      value: markupUnitItem,
      label: markupUnitItem,
    })),
  );

  const { current: spreadTypeOptions } = useRef<ISelectOption[]>(
    SPREAD_TYPE.map((spreadTypeItem) => ({
      value: spreadTypeItem,
      label: spreadTypeItem,
    })),
  );

  return (
    <FormContainer>
      <Header>
        <Title>{id && name && `Edit Feed Provider: ${name}`}</Title>
        <Actions>
          {id && name && (
            <>
              <Tooltip title="Apply changes and close form" placement="bottom">
                <IconButton
                  disabled={!dirty}
                  iconColor={dirty ? 'action' : 'secondary'}
                  iconName="SaveIcon"
                  iconSize={18}
                  label="OK"
                  onClick={handleSave}
                />
              </Tooltip>

              <Tooltip
                title="Delete Feed Provider from instrument"
                placement="bottom"
              >
                <IconButton
                  iconColor="radical"
                  iconName="DeleteIcon"
                  iconSize={18}
                  onClick={handleDelete}
                />
              </Tooltip>
            </>
          )}

          <Tooltip title="Discard changes and close form" placement="bottom">
            <IconButton
              iconColor="secondary"
              iconName="CloseIcon"
              iconSize={18}
              onClick={handleClose}
            />
          </Tooltip>
        </Actions>
      </Header>

      {!id && <Placeholder>Select Feed Provider</Placeholder>}
      {id && !name && <Placeholder>Feed Provider does not exist</Placeholder>}

      {id && name && (
        <Body>
          <TextInput path={`feeds.providerOverrides.${id}.symbolName`}>
            Symbol name
          </TextInput>
          <TextInput path={`feeds.providerOverrides.${id}.exchangeName`}>
            Exchange name
          </TextInput>

          <Section>
            <SectionTitle>Symbol Identifier</SectionTitle>
            <Select
              options={symbolIdentifierTypeOptions}
              path={`feeds.providerOverrides.${id}.symbolIdentifier.type`}
            >
              Type
            </Select>
            <TextInput
              path={`feeds.providerOverrides.${id}.symbolIdentifier.identifier`}
            >
              Identifier
            </TextInput>
          </Section>

          <Section>
            <SectionTitle>Max quote depth</SectionTitle>
            <TextInput path={`feeds.providerOverrides.${id}.maxQuoteDepth`}>
              Max quote depth
            </TextInput>
          </Section>

          <Select
            options={currencyOptions}
            path={`feeds.providerOverrides.${id}.currency`}
          >
            Currency
          </Select>
          <TextInput path={`feeds.providerOverrides.${id}.priceMultiplier`}>
            Price multiplier
          </TextInput>
          <TextInput path={`feeds.providerOverrides.${id}.volumeMultiplier`}>
            Volume multiplier
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.quotePriceMultiplier`}
          >
            Price multiplier (quotes)
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.quoteVolumeMultiplier`}
          >
            Volume multiplier (quotes)
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.tradePriceMultiplier`}
          >
            Price multiplier (trades)
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.tradeVolumeMultiplier`}
          >
            Volume multiplier (trades)
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.strikePriceMultiplier`}
          >
            Strike price multiplier
          </TextInput>
          <TextInput path={`feeds.providerOverrides.${id}.markup`}>
            Markup
          </TextInput>
          <TextInput path={`feeds.providerOverrides.${id}.bidMarkup`}>
            Bid markup
          </TextInput>
          <TextInput path={`feeds.providerOverrides.${id}.askMarkup`}>
            Ask markup
          </TextInput>
          <Select
            options={markupUnitOptions}
            path={`feeds.providerOverrides.${id}.markupUnit`}
          >
            Markup unit
          </Select>
          <TextInput path={`feeds.providerOverrides.${id}.priceDeviation`}>
            Price deviation
          </TextInput>

          <Section>
            <SectionTitle>Maturity date</SectionTitle>
            <MaturityDate path={`feeds.providerOverrides.${id}.maturityDate`} />
          </Section>

          {type === INSTRUMENT_TYPE.CALENDAR_SPREAD && (
            <>
              <TextInput path={`feeds.providerOverrides.${id}.legGap`}>
                Leg gap
              </TextInput>

              <Select
                options={spreadTypeOptions}
                path={`feeds.providerOverrides.${id}.spreadType`}
              >
                Spread type
              </Select>
            </>
          )}

          <TextInput path={`feeds.providerOverrides.${id}.contractMultiplier`}>
            Contract multiplier
          </TextInput>
          <BoolTable>
            <BoolRow
              path={`feeds.providerOverrides.${id}.aggregateTillMinAllowedVolume`}
            >
              Aggregate till min allowed volume
            </BoolRow>
          </BoolTable>
          <TextInput path={`feeds.providerOverrides.${id}.minAllowedVolume`}>
            Min allowed volume
          </TextInput>
          <BoolTable>
            <BoolRow path={`feeds.providerOverrides.${id}.forceAlignToMpi`}>
              Force align to Mpi
            </BoolRow>
            <BoolRow path={`feeds.providerOverrides.${id}.flipQuotes`}>
              Flip quotes
            </BoolRow>
          </BoolTable>

          {providerType && (
            <ProviderType id={id} type={providerType as keyof ProviderTypes} />
          )}

          <Title>Bond data calculation</Title>
          <BoolTable>
            <BoolRow
              path={`feeds.providerOverrides.${id}.bondCalcData.fetchYtm`}
            >
              Fetch ytm
            </BoolRow>
            <BoolRow
              path={`feeds.providerOverrides.${id}.bondCalcData.fetchPrevCouponDate`}
            >
              Fetch prev coupon date
            </BoolRow>
            <BoolRow
              path={`feeds.providerOverrides.${id}.bondCalcData.fetchNextCouponDate`}
            >
              Fetch next coupon date
            </BoolRow>
            <BoolRow
              path={`feeds.providerOverrides.${id}.bondCalcData.fetchDirtyPrice`}
            >
              Fetch dirty price
            </BoolRow>
            <BoolRow
              path={`feeds.providerOverrides.${id}.bondCalcData.fetchAci`}
            >
              Fetch aci
            </BoolRow>
          </BoolTable>

          <Title>Bond data multipliers</Title>
          <TextInput
            path={`feeds.providerOverrides.${id}.bondCalcData.bondDataMultipliers.ytmMultiplier`}
          >
            Ytm multiplier
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.bondCalcData.bondDataMultipliers.aciMultiplier`}
          >
            Aci multiplier
          </TextInput>

          <Title>YTM Limits</Title>
          <TextInput
            path={`feeds.providerOverrides.${id}.bondCalcData.YTMLimits.min`}
          >
            Min
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.bondCalcData.YTMLimits.max`}
          >
            Max
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.bondCalcData.ACIDelta`}
          >
            ACIDelta
          </TextInput>

          <Section>
            <SectionTitle>Aux calc data</SectionTitle>
            <BoolTable>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchChange`}
              >
                Fetch change
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchVolume24`}
              >
                Fetch volume 24
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchDailyVolume`}
              >
                Fetch daily volume
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchSessionOpen`}
              >
                Fetch session open
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchLastSessionClose`}
              >
                Fetch last session close
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchLimitHigh`}
              >
                Fetch limit high
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.auxCalcData.fetchLimitLow`}
              >
                Fetch limit low
              </BoolRow>
            </BoolTable>
          </Section>

          <Section>
            <SectionTitle>Zeus calc data</SectionTitle>
            <BoolTable>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchImpliedVolatility`}
              >
                Fetch implied volatility
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchTheoPrice`}
              >
                Fetch theo price
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchGreeks`}
              >
                Fetch greeks
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchRiskFreeRate`}
              >
                Fetch risk free rate
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchImpliedForwardPrice`}
              >
                Fetch implied forward price
              </BoolRow>
              <BoolRow
                path={`feeds.providerOverrides.${id}.zeusCalcData.fetchVolatilityIndex`}
              >
                Fetch volatility index
              </BoolRow>
            </BoolTable>
          </Section>

          <TextInput path={`feeds.providerOverrides.${id}.minSpreadMarkup`}>
            Min spread markup
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.restartSubscriptionTimeout`}
          >
            Restart subscription timeout
          </TextInput>
          <TextInput
            path={`feeds.providerOverrides.${id}.restartOnAbsentQuotesTimeout`}
          >
            Restart on absent quotes timeout
          </TextInput>
          <BoolTable>
            <BoolRow path={`feeds.providerOverrides.${id}.useACICorrection`}>
              Use ACI correction
            </BoolRow>
            {/** Not getting from backend */}
            <BoolRow
              path={`feeds.providerOverrides.${id}.useBondDiscountQuotation`}
            >
              Use bond discount quotation
            </BoolRow>
          </BoolTable>
        </Body>
      )}
    </FormContainer>
  );
};

export default Form;

import { TLogHandleTimeRequest } from '~/types/models';

import { apiService } from './api/api.service';
import { getApiDomain } from './api/getApiDomain';
import { SESSION_ID_COOKIE_KEY } from './authorization/authorization.const';
import { FETCH_LOG_HANDLE_TIME } from './constants';
import { getSessionIdFromCookie } from './cookies/cookies.service';

const BO_API = apiService(getApiDomain('BO'));

export const influxProxyService = () => {
  const postLogHandleTime = async (
    data: TLogHandleTimeRequest,
  ): Promise<void> => {
    try {
      await BO_API.post({
        endPoint: FETCH_LOG_HANDLE_TIME,
        sessionToken: getSessionIdFromCookie(SESSION_ID_COOKIE_KEY) || '',
        data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    postLogHandleTime,
  };
};

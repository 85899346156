import { InstrumentType } from '~/types/models';

export const INSTRUMENT_TYPE: Record<InstrumentType, InstrumentType> = {
  BOND: 'BOND',
  CALENDAR_SPREAD: 'CALENDAR_SPREAD',
  CFD: 'CFD',
  FOREX: 'FOREX',
  FUND: 'FUND',
  FUTURE: 'FUTURE',
  FX_SPOT: 'FX_SPOT',
  OPTION: 'OPTION',
  STOCK: 'STOCK',
};
